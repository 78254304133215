.no_scroll{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.no_scroll::-webkit-scrollbar {
    display: none;
}
.ReactCollapse--collapse {
    transition: height 300ms;
}
.PhoneInputInput {
    background-color: transparent;
    outline: none;
}
.leaflet-control { z-index: 0 !important}
.leaflet-pane { z-index: 0 !important}
.leaflet-top, .leaflet-bottom {z-index: 0 !important}