@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-Medium.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-SemiBold.otf");
  font-weight: 600;
  font-style: normal;
}